.accordion{
    .collapse-toggle{
        display: none;

        + label {
            cursor: pointer;
            background: $light-grey-1;
            padding: .75rem 1.25rem;
            border-bottom: 2px solid rgba(0,0,0,.125);
            margin-bottom: -1px;

            + .card-body {
                max-height: 0;
                background: $color-white;
                transition: all .15s;
                padding: 0;

                form{
                    margin-bottom: 0;
                }
            }
        }

        &:checked{
            + label {
                + .card-body {
                    padding: 1.25rem;
                    max-height: 10000vh;
                }
            }
        }
    }

    .card{
        .badge{
            font-weight: 600;
        }
    }
}

