.tabs {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .tab-label {
        order: 1;
        display: block;
        padding: 10px;
        margin-right: 0.2rem;
        cursor: pointer;
        transition: background ease 0.2s;
        margin-bottom: 0;
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-weight: bold;
        line-height: 1.47;
        color: $dark-grey-4;
    }

    .tab-content {
        order: 99;
        flex-grow: 1;
        width: 100%;
        display: none;
        padding: 2rem;
        background: #fff;
        border: 1px solid $dark-grey-1;
    }

    > input[type="radio"] {
        display: none;

        &:checked + label {
            border: 1px solid $dark-grey-1;
            border-bottom: 0;
            position: relative;
            color: $dark-grey-1;

            + .tab-content {
                display: block;
            }

            &::after {
                content: " ";
                display: block;
                height: 5px;
                background: $color-white;
                position: absolute;
                width: 100%;
                bottom: -3px;
                left: 0;
            }
        }
    }
}

.btn-group {
    padding: 0 0 .5rem .5rem;

    &::after {
        content: " ";
        display: block;
        height: 5px;
        background: $color-white;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 3px;
    }

    input {
        display: inline-block;
        width: auto;
        margin: 0 10px;
    }

    .btn {
        margin: 0 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    > .btn{
        &:not(:first-child){
            margin-left: 10px;
        }
    }
}

form{
    .tabs{
        margin-top: 0.5rem;

        input[type="radio"] {
            &:checked + label {
                &::after {
                    background: $light-grey-2;
                }
            }
        }

        .tab-content{
            background: none;
        }
    }
}
