.btn {
    display: inline-block;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.9375rem;
    padding: 8px 20px;
    white-space: nowrap;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    margin: 0 15px;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    i {
        color: $color-white;
        margin-right: 10px;
    }
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.btn-primary {
    background-color: $primary-blue;
    color: white;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $dark-grey-2;
        color: white;
    }
}

.btn-secondary {
    background-color: $dark-grey-2;
    color: white;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $primary-blue;
        color: white;
    }
}

.btn-warning, .btn-danger {
    background-color: $color-orange;
    color: white;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $dark-grey-2;
        color: white;
    }
}

.btn-dark-grey {
    background-color: $dark-grey-3;
    color: white;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $dark-grey-2;
        color: white;
    }
}

.input-button {
    padding-right: 0;
    display: inline-flex;
    margin-bottom: 30px;

    input {
        display: inline-block;
        width: auto;
        margin: 0 10px;
        border: 1px solid $light-grey-1 !important;
    }

    .btn {
        margin: 0 10px;
    }

    *:last-child {
        margin-right: 0;
    }

    *:first-child {
        margin-left: 0;
    }
}


a.btn{
    color: $white !important;
    text-transform: none;
    overflow: visible;
}

.modal-body{
    .btn{
        font-size: 15px;
        line-height: 1.53;
        padding: 7px 83px;
    }
}
