    /*
    Flaticon icon font: Flaticon
    Creation date: 07/12/2020 15:20
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticons/Flaticon.eot");
  src: url("../fonts/flaticons/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticons/Flaticon.woff2") format("woff2"),
       url("../fonts/flaticons/Flaticon.woff") format("woff"),
       url("../fonts/flaticons/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticons/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticons/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-user:before { content: "\f100"; }
.flaticon-time:before { content: "\f101"; }
.flaticon-search:before { content: "\f102"; }
.flaticon-tick:before { content: "\f103"; }
.flaticon-turn-off:before { content: "\f104"; }
.flaticon-phone-call:before { content: "\f105"; }
.flaticon-sort-by-attributes:before { content: "\f106"; }
.flaticon-sort-by-attributes-interface-button-option:before { content: "\f107"; }
.flaticon-sort:before { content: "\f108"; }
.flaticon-sort-1:before { content: "\f109"; }
.flaticon-sort-2:before { content: "\f10a"; }
.flaticon-sort-3:before { content: "\f10b"; }
.flaticon-export:before { content: "\f10c"; }
.flaticon-euro:before { content: "\f10d"; }
.flaticon-switch:before { content: "\f10e"; }
.flaticon-toggle:before { content: "\f10f"; }
.flaticon-arroba:before { content: "\f110"; }
.flaticon-right-arrow:before { content: "\f111"; }
.flaticon-sort-4:before { content: "\f112"; }
.flaticon-time-1:before { content: "\f113"; }
.flaticon-lock:before { content: "\f114"; }
.flaticon-lock-1:before { content: "\f115"; }
.flaticon-eye:before { content: "\f116"; }
.flaticon-export-1:before { content: "\f117"; }
.flaticon-sheet:before { content: "\f118"; }
.flaticon-door:before { content: "\f119"; }
.flaticon-enter:before { content: "\f11a"; }
.flaticon-cash-register:before { content: "\f11b"; }
.flaticon-info:before { content: "\f11c"; }
.flaticon-warning:before { content: "\f11d"; }
.flaticon-email:before { content: "\f11e"; }
.flaticon-calendar:before { content: "\f11f"; }
.flaticon-paper-plane:before { content: "\f120"; }
.flaticon-pencil:before { content: "\f121"; }
.flaticon-trash:before { content: "\f122"; }
.flaticon-user-1:before { content: "\f123"; }
.flaticon-download:before { content: "\f124"; }
.flaticon-unlock:before { content: "\f125"; }
.flaticon-lock-2:before { content: "\f126"; }
.flaticon-dashed-circle:before { content: "\f127"; }
.flaticon-circle-outline:before { content: "\f128"; }
.flaticon-settings:before { content: "\f129"; }
.flaticon-check:before { content: "\f12a"; }
.flaticon-plus:before { content: "\f12b"; }
.flaticon-cancel:before { content: "\f12c"; }
.flaticon-star:before { content: "\f12d"; }

    $font-Flaticon-user: "\f100";
    $font-Flaticon-time: "\f101";
    $font-Flaticon-search: "\f102";
    $font-Flaticon-tick: "\f103";
    $font-Flaticon-turn-off: "\f104";
    $font-Flaticon-phone-call: "\f105";
    $font-Flaticon-sort-by-attributes: "\f106";
    $font-Flaticon-sort-by-attributes-interface-button-option: "\f107";
    $font-Flaticon-sort: "\f108";
    $font-Flaticon-sort-1: "\f109";
    $font-Flaticon-sort-2: "\f10a";
    $font-Flaticon-sort-3: "\f10b";
    $font-Flaticon-export: "\f10c";
    $font-Flaticon-euro: "\f10d";
    $font-Flaticon-switch: "\f10e";
    $font-Flaticon-toggle: "\f10f";
    $font-Flaticon-arroba: "\f110";
    $font-Flaticon-right-arrow: "\f111";
    $font-Flaticon-sort-4: "\f112";
    $font-Flaticon-time-1: "\f113";
    $font-Flaticon-lock: "\f114";
    $font-Flaticon-lock-1: "\f115";
    $font-Flaticon-eye: "\f116";
    $font-Flaticon-export-1: "\f117";
    $font-Flaticon-sheet: "\f118";
    $font-Flaticon-door: "\f119";
    $font-Flaticon-enter: "\f11a";
    $font-Flaticon-cash-register: "\f11b";
    $font-Flaticon-info: "\f11c";
    $font-Flaticon-warning: "\f11d";
    $font-Flaticon-email: "\f11e";
    $font-Flaticon-calendar: "\f11f";
    $font-Flaticon-paper-plane: "\f120";
    $font-Flaticon-pencil: "\f121";
    $font-Flaticon-trash: "\f122";
    $font-Flaticon-user-1: "\f123";
    $font-Flaticon-download: "\f124";
    $font-Flaticon-unlock: "\f125";
    $font-Flaticon-lock-2: "\f126";
    $font-Flaticon-dashed-circle: "\f127";
    $font-Flaticon-circle-outline: "\f128";
    $font-Flaticon-settings: "\f129";
    $font-Flaticon-check: "\f12a";
    $font-Flaticon-plus: "\f12b";
    $font-Flaticon-cancel: "\f12c";
    $font-Flaticon-star: "\f12d";
