$primary-blue: #003571;
$color-green: #44801e;
$color-orange: #bf5a09;
$color-white: #ffffff;
$dark-grey-1: #424b4c;
$dark-grey-2: #63666a;
$dark-grey-3: #848485;
$dark-grey-4: #9b9b9b;
$light-grey-1: #d8d8d8;
$light-grey-2: #efeff0;

$color-text-default: $dark-grey-1;

.color-blue {
    background: $primary-blue;
}

.color-green {
    background: $color-green;
}

.color-orange {
    background: $color-orange;
}

.color-white {
    background: $color-white;
}

.color-dark-grey-1 {
    background: $dark-grey-1;
}

.color-dark-grey-2 {
    background: $dark-grey-2;
}

.color-dark-grey-3 {
    background: $dark-grey-3;
}

.color-dark-grey-4 {
    background: $dark-grey-4;
}

.color-light-grey-1 {
    background: $light-grey-1;
}

.color-light-grey-2 {
    background: $light-grey-2;
}
