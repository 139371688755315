form, .form {
    background-color: $light-grey-2;
    margin-bottom: 45px;
    padding: 25px 38px;

    p {
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9375rem;
        line-height: 1.53;
        margin-bottom: 20px;
    }

    h2,
    .h2 {
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 0.9375rem;
        line-height: 1.53333;
        margin-bottom: 20px;
    }

    .form-button {
        margin-top: 20px;
        margin-bottom: 0;
    }

    > .accordion{
        margin: 13px 0;

        input[type=text].form-control, input[type=email].form-control, input[type=tel].form-control, input[type=password].form-control, textarea.form-control
        {
            border: 1px solid $light-grey-1;
        }
    }

    a{
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-style: normal;
        font-size: 0.9375rem;
    }

    ul.link-list--horizontal{
        padding: 0;
        list-style: none;

        li{
            display: inline-block;
            margin-right: 5px;

            a.active{
                font-weight: 500;
            }

            a.active, a.disabled{
                cursor: default;
                pointer-events: none;
            }

            a.disabled{
                opacity: 0.5;
            }
        }
    }
}

.form__label {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.9375rem;
    line-height: 1.53333;
    display: block;
    margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
input[type=date] {
    &.form-control {
        height: 35px;

        &.input-text-bg-grey {
            background-color: $light-grey-2;
            color: $dark-grey-1;
            font-weight: 500;
            line-height: 1.47;
        }
    }
}

.form__select {
    &.input-text-bg-grey {
        background-color: $light-grey-2;
        color: $dark-grey-1;
        font-weight: 500;
        line-height: 1.47;
    }
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
input[type=date],
textarea {
    &.form-control {
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9375rem;
        line-height: 1.53;
        width: 100%;
        background: $color-white;
        padding: 6px 13px;
        border: 0;
        border-radius: 0;
        vertical-align: middle;

        &.form-error {
            border: solid 2.5px $color-orange;
            box-shadow: none;

            &:focus {
                box-shadow: none;
                border-color: $color-orange;
            }

            &::-webkit-input-placeholder {
                font-weight: 500;
                color: $color-orange;
                opacity: 1;
            }

            &::placeholder {
                font-weight: 500;
                color: $color-orange;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                font-weight: 500;
                color: $color-orange;
                opacity: 1;
            }

            &::-moz-placeholder{
                font-weight: 500;
                color: $color-orange;
                opacity: 1;
            }
        }
    }
}

.custom-file {
    height: 35px;

    &.form-error {
        border: solid 2.5px $color-orange;
        box-shadow: none;

        .custom-file-label {
            line-height: 1.1;

            &::after {
                line-height: 1.1;
            }
        }
    }
}

.custom-file-input {
    height: 100%;
}

.custom-file-label {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9375rem;
    line-height: 1.53;
    width: 100%;
    background: $color-white;
    padding: 6px 13px;
    border: 0;
    border-radius: 0;
    vertical-align: middle;
    height: 100%;

    &::after {
        height: 100%;
    }

    &.form-error {
        border: solid 2.5px $color-orange;
        box-shadow: none;

        &:focus {
            box-shadow: none;
            border-color: $color-orange;
        }

        &::-webkit-input-placeholder {
            font-weight: 500;
            color: $color-orange;
            opacity: 1;
        }

        &::placeholder {
            font-weight: 500;
            color: $color-orange;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            font-weight: 500;
            color: $color-orange;
            opacity: 1;
        }

        &::-moz-placeholder{
            font-weight: 500;
            color: $color-orange;
            opacity: 1;
        }
    }
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
    &.form-control {
        &.form-control-lg {
            height: auto;
            font-family: 'Fira Sans', sans-serif;
            width: 100%;
            padding: 15px 10px;
        }
    }
}

.form__select-container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 0;
    background: $color-white;
    overflow: hidden;

    &::after {
        display: block;
        content: '»';
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 20px;
        text-align: center;
        background: $light-grey-1;
        pointer-events: none;
    }

    &.form-error{
        border: solid 2px #bf5a09;
        box-shadow: none;

        &::after {
            line-height: 16px;
            width: 16px;
        }
    }
}

.form__select {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.8125rem;
    line-height: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    background: $color-white;
    background-image: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 28px 0 0.5em;
    cursor: pointer;
}

.form__button--right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    .form__button-container {
        text-align: right;
    }
}

.form-check-label {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9375rem;
    line-height: 1.53;
}

.form-check-input {
    margin-top: .38rem;
}

.form__button-add-link-container {
    display: inline-block;
}

.form__button-add-link {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.8125rem;
    line-height: 1.61538;
    display: inline-block;
    position: relative;
    padding-left: 15px;
    margin-top: 5px;
}

.input-group {
    input[type=text] {
        width: auto;
    }

    &.input-search-icon {
        background-color: $light-grey-2;

        .input-group-text {
            border: 0;
            color: $dark-grey-1;
            padding-right: 0;
        }

        input[type=text] {
            margin-left: 1px;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.form-button {
    margin-bottom: 30px;
}

label.required-field {
    &::after{
        content: "*";
    }
}

.form__select-container--large {
    min-height: 35px;

    .form__select {
        font-size: 0.9rem;
    }

    &::after {
        width: 35px;
        line-height: 35px;
    }
}

.custom-file-label {
    &::after {
        content: "Suchen";
        background-color: $light-grey-1;
        border-radius: 0;
    }
}

.form-group,
form,
.form {
    .btn {
        padding: 6px 20px;
    }

    a {
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 0.8125rem;
        line-height: 1.61538;
        display: inline-block;
        position: relative;
        margin-top: 5px;

        &.btn {
            margin-top: 0;
            height: 100%;
        }
    }

    i.fi {
        &::before {
            font-size: 15px;
        }
    }

    .select2-container--default {
        .select2-selection--multiple {
            border: 0;
            border-radius: 0;
            line-height: 1;

            .select2-selection__choice {
                font-family: 'Fira Sans Extra Condensed', sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 0.9375rem;
                padding: 0 0.25em;
                vertical-align: middle;
                background-color: #f0f0f0;
                border: 1px solid rgba(60,60,60,.26);
                line-height: 22px;
                margin-bottom: 3px;
            }

            .select2-search.select2-search--inline {
                line-height: 0;
                margin-bottom: 0;
            }
        }
    }

    ul {
        li {
            margin-bottom: 15px;
        }
    }
}

.select2-container--default {
    .select2-results__options {
        .select2-results__option[aria-selected] {
            font-family: 'Fira Sans Extra Condensed', sans-serif;
            font-size: 0.9375rem;
        }
    }
}
