table {
    border-top: 3px solid $dark-grey-1;
    margin-bottom: 30px !important;

    th {
        background-color: $light-grey-2;

        .sort-icon {
         cursor: pointer;
            &::after {
                opacity: 0.3;
                margin-left: 10px;
                display: inline-block;
                font-family: "Flaticon";
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                line-height: 1;
                text-decoration: inherit;
                text-rendering: optimizeLegibility;
                text-transform: none;
            }
        }

        .header-container {
            height: 100%;
            vertical-align: middle;
            display: flex;
            align-items: center;
        }

        .header-text {
            font-family: 'Fira Sans Extra Condensed', sans-serif;
            font-style: normal;
            font-size: 0.935rem;
            line-height: 1.4;
            vertical-align: middle !important;
            font-weight: 500;
            max-width: 122px;
            display: flex;
            align-items: center;
        }
    }

    td {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-size: 0.935rem;
        line-height: 1.47;
        font-weight: 400;
        vertical-align: middle !important;

        .action-icon {
            margin-right: 7px;

            &:hover {
                font-weight: normal !important;
            }

            i {
                font-size: 1.5em !important;
            }
        }
    }

    &.table th,
    &.table td,
    th,
    td {
        padding: 7px;
    }

    &.table thead,
    thead {
        th {
            border-bottom: none;
            vertical-align: middle;
        }
    }

    td,
    &.table td {
        border-top: none;
        border-bottom: 1px solid $light-grey-1;
    }
}

.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
    background-color: $light-grey-2;
}

.table-striped > tbody > tr:nth-child(2n+1) > td,
.table-striped > tbody > tr:nth-child(2n+1) > th {
    background-color: $color-white;
}

.sort-alpha {
    &::after {
        content: "\f108";
        font-size: 20px;
        opacity: 0.3;
    }
    &.asc {
        &::after {
            content: "\f108";
            opacity: 1;
        }
    }
}

.sort-alpha {
    &::after {
        content: "\f108";
        font-size: 20px;
        opacity: 0.3;
    }
    &.desc {
        &::after {
            content: "\f109";
            opacity: 1;
        }
    }
}

.sort-amount {
    &::after {
        content: "\f107";
        font-size: 20px;
        opacity: 0.3;
    }
    &.asc {
        &::after {
            content: "\f107";
            opacity: 1;
        }
    }
}

.sort-amount {
    &::after {
        content: "\f107";
        font-size: 20px;
        opacity: 0.3;
    }
    &.desc {
        &::after {
            content: "\f106";
            opacity: 1;
        }
    }
}

.sort-numeric {
    &::after {
        content: "\f10b";
        font-size: 20px;
        opacity: 0.3;
    }
    &.asc {
        &::after {
            content: "\f10b";
            opacity: 1;
        }
    }
}

.sort-numeric {
    &::after {
        content: "\f10b";
        font-size: 20px;
        opacity: 0.3;
    }
    &.desc {
        &::after {
            content: "\f10a";
            opacity: 1;
        }
    }
}

div.table-length select {
    width: auto;
    display: inline-block;
    margin-right: 5px;
}

.custom-select {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.table-length {
    margin-bottom: .5rem;

    label {
        margin-bottom: 0;
        font-size: 90%;
    }
}

.main-content {
    table {
        td {
            ul {
                margin-bottom: 0;

                li {
                    &:not(.page-item) {
                        font-size: 0.935rem;
                    }
                }
            }
        }
    }
}
