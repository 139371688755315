.header-stage {
    background-color: $primary-blue;
}

.header-stage__container {
    min-height: 120px;
    padding: 40px 0;

    h1,
    .h1 {
        color: $color-white;
        font-size: 32px;
        margin-bottom: 0;
    }
}

.header-stage--background{
    position: relative;
    background-color: #003571;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .container{
        position: relative;
        z-index: 2;
    }

    &::after{
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 53, 113, 0.75);
        z-index: 1;
    }
}
