.main-navigation {
    padding-top: 50px;
    padding-bottom: 0;
}

.navbar-expand {
    .navbar-nav {
        position: relative;

        .nav-link {
            font-family: 'Fira Sans', sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 1.1875rem;
            line-height: 1.32;
            color: $primary-blue;
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $primary-blue;
                color: $color-white;
            }
        }

        .nav-item {
            &.active {
                .nav-link {
                    background-color: $primary-blue;
                    color: $color-white;
                }
            }
        }
    }

    > .container {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.navbar-brand {
    margin-top: -20px;
    padding-bottom: 10px;
}

.submenu__wrapper {
    display: none;
    position: absolute;
    z-index: 3;
    top: 55px;
    background: $color-white;
    list-style: none;
    padding: 6px 17px;
    min-width: 250px;
    border: 1px solid $light-grey-1;
    border-top: 0;

    li {
        display: block;
        padding: 15px 0;
        border-bottom: 1px solid $light-grey-1;

        a {
            &.active {
                font-weight: 600;
            }
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }
}

.nav-item {
    &.has-submenu {
        &:hover {
            .submenu__wrapper {
                display: block;
            }

            .nav-link {
                background-color: $primary-blue;
                color: $color-white;
            }
        }
    }
}

.top-navigation {
    background-color: $light-grey-2;
}

.top-navigation__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 100%;
    align-items: center;
    padding-left: 10px;

    span {
        font-family: 'Fira Sans', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9375rem;
        line-height: 1.4;
    }
}

.top-navigation__box-left {
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .top-navigation__link {
        border-right: 1px solid $color-white;
    }
}

.top-navigation__box-right {
    width: auto;
    display: inline-block;
    line-height: 0;
    font-size: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .top-navigation__link {
        border-left: 1px solid white;
    }
}

.top-navigation__item {
    display: inline-block;
    position: relative;
}

.top-navigation__link {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9375rem;
    line-height: 1.4;
    display: block;
    position: relative;
    padding: 10px 20px;

    span {
        display: inline-block;
        color: $dark-grey-1;
    }

    &:hover,
    &:focus {
        font-weight: 400;

        span {
            color: $primary-blue;
        }
    }
}
