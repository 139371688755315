.headline-main {
    @include fs(30, 36, conex-med);

    color: $primary-blue;
    margin-bottom: 24px;
}

h1,
.h1 {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 30px;
}

h5,
.h5 {
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    line-height: 1.3;
    margin-bottom: 30px;
}
