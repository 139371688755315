@mixin posabs-tl {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

@mixin link-behaviour-std($color: $color-text-default) {
    &:hover {
        // font-style: italic;
        font-weight: 600;
        color: $color;
    }

    &:focus {
        text-decoration: underline;
        color: $color;
    }
}

@mixin hover-behaviour-std($color: $color-text-default) {
    &:hover {
        // font-style: italic;
        font-weight: 600;
        color: $color;
    }
}
