* {
    @include font-regular;

    color: $color-text-default;
}

.not-selectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
