.flaticon-download, .flaticon-time-1, .flaticon-warning, .flaticon-sort-4{
    &:before{
        font-weight: bold !important;
    }
}

table{
    td{
        .action-icon{
            .flaticon-unlock, .flaticon-lock-2{
                font-size: 1.43rem !important;
            }
        }
    }
}


i.flaticon-switch, i.flaticon-toggle{
    vertical-align: middle;
    line-height: .1;
    margin-top: 10px;
    display: inline-block;

    &:before{
        line-height: .1;
    }

    + span.tooltiptext{
        bottom: 163%;
    }
}

table{
    td{
        .action-icon{
            i.flaticon-switch, i.flaticon-toggle{
                font-size: 1.9em !important;
            }
        }
    }
}

a.action-icon + a.action-icon, a.action-icon + span.action-icon, span.action-icon + a.action-icon, span.action-icon + span.action-icon{
    .flaticon-switch, .flaticon-toggle{
        margin-top: 4px;

        + span.tooltiptext{
            bottom: 177%;
        }
    }
}

a.action-icon:first-of-type:not(:only-child), span.action-icon:first-of-type:not(:only-child){
    .flaticon-switch, .flaticon-toggle{
        margin-top: 4px;

        + span.tooltiptext{
            bottom: 177%;
        }
    }
}
