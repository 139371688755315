.block-list {
    margin-bottom: 30px;
}

.block-list__item {
    background-color: $light-grey-2;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    cursor: default;

    [class*=col] {
        padding: 0;
    }

    .block-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .row {
        margin: 0;
    }
}

button.block-icon {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.block-list--type-2 {
    .block-list__item {
        cursor: unset;
        padding-left: 0;

        .block-icon {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            transform: none;
            text-align: center;

            .handle {
                cursor: move;
            }
        }


    }

    a {
        cursor: pointer;
    }
}

a {
    cursor: pointer;
}



.ban {
    cursor: not-allowed;
}

.block-list__sublist {
    position: relative;

    .block-list__subitem {
        padding: 8px 0;
        position: relative;

        .block-icon {
            text-align: left;
        }
    }
}
