a {
    color: $dark-grey-1;

    &:not(.btn):not(.nav-link) {
        &:hover {
            font-weight: 600;
            color: $dark-grey-1;
            text-decoration: none;
        }
    }
}

.main-content {
    padding-top: 45px;
    margin-bottom: 45px;
}

.main-content {
    .transparent-table {
        .content {
            > ul {
                > li {
                    padding-left: 40px;
                }
            }
        }
    }

    .form-group {
        ul,
        ol {
            li:not(.page-item) {
                padding: 0;

                &:before {
                    display: none;
                }
            }
        }
    }

    ul,
    ol {
        list-style: none;
        padding: 0;
        margin-bottom: 25px;

        li:not(.page-item) {
            @include fs(19, 25, reg);
            font-size: 17px;
            line-height: 30px;
            padding-left: 20px;
            position: relative;

            &:before {
                @include posabs-tl;
            }

            .form-group {
                .btn {
                    i.fi {
                        font-size: inherit;
                    }
                }
            }

            i {
                font-size: 23px;
            }
        }
    }

    ol {
        counter-reset: ol-li-counter;
    }

    ol li {
        counter-increment: ol-li-counter;

        ol{
            margin: 0;
        }

        &:before {
            content: counter(ol-li-counter) '. ';
        }
    }

    ul li:not(.page-item) {
        ul {
            margin: 0;

            li {
                padding-left: 20px;

                &::before {
                    display: inline-block;
                    font-family: "Flaticon";
                    font-style: normal;
                    font-weight: bold;
                    font-variant: normal;
                    text-decoration: inherit;
                    text-rendering: optimizeLegibility;
                    text-transform: none;
                    font-size: 10px;
                }
            }
        }

        &::before {
            display: inline-block;
            font-family: "Flaticon";
            font-style: normal;
            font-weight: bold;
            font-variant: normal;
            text-decoration: inherit;
            text-rendering: optimizeLegibility;
            text-transform: none;
            font-size: 10px;
            content: "\f111";
            color: $color-green;
        }

        &.icon-list {
            padding-left: 0;
            text-align: center;

            &::before {
                content: none;
                display: none;
            }
        }

        &.icon-list-item {
            &:before {
                display: none;
            }

            i {
                line-height: 25px;
                @include posabs-tl;
            }

            strong, b{
                font-weight: 500 !important;
            }
        }
    }
}

.transparent-table__header {
    border-bottom: 1px solid $dark-grey-1;
    padding: 10px 0;
    margin-bottom: 30px;

    span {
        font-family: 'Fira Sans', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.18;
        color: $dark-grey-1;
        text-transform: uppercase;
    }
}

.transparent-table__body {
    padding-bottom: 18px;
    padding-right: 28px;
}

.transparent-table {
    padding: 10px;
}

hr{
    border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.sortable-chosen, .sortable-ghost {
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
}

.sortable-drag {
    opacity: 0;
}

.keywords {
    max-height: 2200px;
    padding-right: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.keywords-list {
    margin-left: 57px;
}

.text-grey {
    color: #efeff0;
}

.handle {
    cursor: move;
}

.ban {
    cursor: not-allowed;
}

.flip-list-move {
    transition: transform 0.5s;
}

.link--plus {
    position: relative;
    padding-left: 13px;

    &::before {
        display: block;
        content: '+';
        font-size: 1.2rem;
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        top: 50%;
        line-height: 1;
    }
}
