.pagination {
    margin-top: 30px;

    .page-item {
        margin-right: 4px;

        &:last-of-type {
            margin-right: 0;
        }

        a.page-link {
            &:hover {
                font-weight: 500;
                background-color: $dark-grey-4;
            }
            font-size: 15px;
            font-weight: 500;
            line-height: 1.4;
            color: $color-white;
            background-color: $primary-blue;
            padding: 4px 9px;
            border-radius: 0;
            border: 0;
            height: 100%;
        }

        &.disabled{
            .page-link{
                background-color: lighten($dark-grey-4, 23%);
                pointer-events: none;
                cursor: auto;
            }
        }

        &.active {
            .page-link {
                border-color: transparent;
                background-color: $dark-grey-4;
                color: $color-white;
            }
        }
    }

    &.pagination--type-2 {
        .page-item {
            position: relative;
            height: 22px;
            font-size: 15px;
            line-height: 21px;
            margin-right: 5px;

            &.page-item__label {
                width: auto;
                margin-right: 15px;
            }

            &.active {
                .page-link {
                    border-color: transparent;
                    background-color: $primary-blue;
                    color: $color-white;
                }
            }

            .page-link {
                &:hover {
                    font-weight: 400;
                    background-color: $primary-blue;
                    color: $color-white;
                }
                background-color: transparent;
                text-align: center;
                font-weight: 400;
                color: $dark-grey-2;
                padding: 0 9px;
                border: 0;
                height: 100%;
            }

            &.disabled {
                .page-link {
                    opacity: 0.5;
                    pointer-events: none;
                    cursor: auto;
                }
            }

            &--first {
                .page-link {
                    background-color: $light-grey-2;

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-left: 2px solid;
                        border-bottom: 2px solid;
                        transform: translate(-50%, -50%) rotate(45deg);
                        top: 50%;
                        left: 45%;
                    }

                    &:after {
                        left: 70%;
                    }
                }
            }

            &--prev {
                .page-link {
                    background-color: $light-grey-2;

                    &:before {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-left: 2px solid;
                        border-bottom: 2px solid;
                        transform: translate(-50%, -50%) rotate(45deg);
                        top: 50%;
                        left: 55%;
                    }
                }
            }

            &--next {
                .page-link {
                    background-color: $light-grey-2;

                    &:before {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-left: 2px solid;
                        border-bottom: 2px solid;
                        transform: translate(-50%, -50%) rotate(-135deg);
                        top: 50%;
                        left: 45%;
                    }
                }
            }

            &--last {
                .page-link {
                    background-color: $light-grey-2;

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        box-sizing: border-box;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-left: 2px solid;
                        border-bottom: 2px solid;
                        transform: translate(-50%, -50%) rotate(-135deg);
                        top: 50%;
                        left: 35%;
                    }

                    &:after {
                        left: 61%;
                    }
                }
            }
        }
    }
}
