.alert-danger,
.alert-success {
    font-family: 'Fira Sans', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 19px;
    line-height: 1.4;
    background-color: $color-orange;
    color: $color-white;
    padding: 35px 25px;
    border: 0;
    border-radius: 0;
}

.alert-danger {
    background-color: $color-orange;
}

.alert-success {
    background-color: $color-green;
}

.alert {
    margin-bottom: 30px;
}
