ul.vs__dropdown-menu{
    border-top: 1px solid rgba(60,60,60,.26);
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.8125rem;
}

.vs__selected-options{
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.8125rem;
    line-height: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    background: #ffffff;
    background-image: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-right: 35px !important;
}

.vs__dropdown-toggle{
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
}
.v-select{
    position: relative;

    &.form-error{
        border: solid 3px #bf5a09;
        box-shadow: none;
    }
}

.vs__actions {
    .vs__open-indicator{
        display: none !important;
    }

    &::after{
        display: block;
        content: '»';
        transform: rotate(90deg) translateX(-50%);
        -webkit-transform: rotate(90deg) translateX(-50%);
        font-size: 15px;
        line-height: 30px;
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        text-align: center;
        background: #d8d8d8;
    }
}

.vs__selected{
    margin: 4px 2px 4px !important;
}

.vs__search, .vs__search:focus{
    height: 26px;
}

.ql-editor{
    background-color: white;
}

.quillWrapper{
    &.form-error{
        border: solid 3px #bf5a09;
        box-shadow: none;
    }
}
