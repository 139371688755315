.info-popup{
    margin-left: 15px;
    position: fixed;
    bottom: 0;
    left: 0;

    #info-popup__trigger{
        display: none;
        width: 100px;
        height: 100px;

        + label{
            margin-top: 10px;

            i {
                font-size: 1.3rem;
            }
        }
    }

    .info-popup__content{
        display: none;
        position: absolute;
        bottom: 45px;
        background: #fff;
        width: 300px;
        padding: 15px 15px 0 15px;
        border: 1px solid $dark-grey-4;
        border-radius: 4px;
        font-size: .8rem;
    }

    input[type="checkbox"] + label{
        cursor: pointer;
    }

    &:hover{
        .info-popup__content{
            display: block;
        }
    }
}
